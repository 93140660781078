import Header from '../components/Header'
import Footer from '../components/Footer'
import './About.css'
import Background from '../components/Background'


export default function About(){
  return(
    <div className="main-content">
      <Header />
      <main>
      <div className="borderBox">
        <title>About</title>
      <p className="AboutUs-Header">About Us</p>
      <p className="AboutUs-Text">At Sonaia LLC, we're driven by the pursuit of innovation. Our business stands at the intersection of international trade and software development, even being previously granted a patent to redefine industry standards. With robust storage solutions and a global reach, we're not just keeping pace with the market—we're setting the tempo.</p>
      </div>

      </main>

      <footer>
      <Footer />
      </footer>
    </div>
  )
}
