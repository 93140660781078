import './Header.css';
import logo from '../sonaia-cropped.png';



export default function Header() {
  return(

    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <nav className="App-nav">
          <a href="/home" className="nav-button">Home</a>
          <a href="/about" className="nav-button">About</a>
          <a href="/contact" className="nav-button">Contact</a>
        </nav>
      </header>
      
    </div>
  )
}

