import Header from '../components/Header'
import Footer from '../components/Footer'
import './Contact.css'

export default function Contact(){
  return(
    <div className="main-content">
    <Header />
    <main>
    <div className="Contact-Box">
      <title>Contact</title>
    <p className="Contact-Header">Contact Information</p>
    <p className="Contact-Info">support@sonaia.com</p>
    <p className="Contact-Footer">quick responses typically within 1-3 business days</p>
    </div>
    </main>
    <footer>
    <Footer />
    </footer>
  </div>
  )
}