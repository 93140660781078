import Header from '../components/Header';
import Footer from '../components/Footer';
import './Home.css';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className="main-content">
      <Header />
      <main>
        <div className="opaque-box">
          <title>Home</title>
          <p className="home-title">The Art of Business Ingenuity</p>
          <p className="home-description">
            SONAIA LLC excels in delivering innovative and quality-driven solutions across the industry. Our expertise in cutting-edge technology and dedication to superior service defines our brand, ensuring enduring partnerships and a distinguished market presence.
          </p>
          <Link to="/about" className="learn-more-button">Learn More</Link>
        </div>
       
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
