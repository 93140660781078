/*import React from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'; 
import './App.css';
import logo from './image2vector (1).svg'; // Replace with your logo path
import bgImage from './bbground.jpeg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <nav className="App-nav">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
        </nav>
      </header>
      <main className="App-content">
        
        <h1></h1>
        <p></p>
        <img src={bgImage} className="bgImage" alt="Background" />

      </main>
      <footer className="App-footer">
        © 2023 SONAIA LLC. All rights reserved.
      </footer>
    </div>
  );
}

export default App;

*/
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import NoPage from './pages/NoPage'





export default function App() {
  return(
    <div>
      <title>Sonaia</title>
      <BrowserRouter basename="/">
      <Routes>
     
        <Route index element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="*" element={<NoPage/>} />
    

      </Routes>
      </BrowserRouter>
    </div>
  )
}
